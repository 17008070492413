<template>
  <article v-if="isVisible" class="message" :class="type">
    <div class="message-header">
      <p>
        {{ messageTitle }}
      </p>
      <button class="delete" aria-label="delete" @click="closeMessage" />
    </div>
    <div class="message-body">
      <slot />
      <div v-if="content" v-html="content" />
    </div>
  </article>
</template>

<script setup lang="ts">
const props = defineProps({
  type: {
    type: String,
    default: '',
  },
  title: {
    type: String,
    default: '',
  },
  content: {
    type: String,
    required: false,
    default: '',
  },
  autoHide: {
    type: Boolean,
    default: false,
  },
})

const messageTitle = computed(() => {
  let type = props.type
  if (props.title) {
    type = ''
  }
  switch (type) {
    case 'is-success':
      return 'Erfolgreich!'
    case 'is-danger':
      return 'Fehler'
    default:
      return props.title
  }
})

const isVisible = ref(true)
const closeMessage = () => {
  nextTick(() => {
    isVisible.value = false
  })
}

onMounted(() => {
  if (props.autoHide) {
    setTimeout(closeMessage, 1000)
  }
})
</script>
